<template>
  <div>
    <b-row>
      <b-col class="mb-3">
        <h1>Chat Report</h1>
      </b-col>
    </b-row>
    <b-form ref="field-form">
      <div class="form-group row">
        <div class="col-sm-2">
          <label>Bot</label>
        </div>
        <div class="col-sm-2">
          <bot-selector
            @botChanged="
              (botId) => {
                queryParams.botId = botId;
              }
            "
          />
        </div>
        <div class="col-sm-2">
          <label>Date Range</label>
        </div>
        <div class="col-sm-4">
          <date-range-picker
            ref="picker"
            :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
            :minDate="minDate"
            :maxDate="maxDate"
            :timePicker="true"
            :ranges="defaultRanges()"
            v-model="dateRange"
          >
            <div slot="input" slot-scope="picker" class="form-inline">
              <small class="text-muted mr-1">from</small>
              <span class="form-control form-control-sm">{{
                picker.startDate | datetime
              }}</span>
              <small class="text-muted mx-1">to</small>
              <span class="form-control form-control-sm">{{
                picker.endDate | datetime
              }}</span>
            </div>
          </date-range-picker>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-12">
          <div v-if="isBusy" class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
          <b-button
            variant="primary"
            size="sm"
            class="float-right mx-2"
            @click="getData"
          >
            <b-icon-search />
            Search
          </b-button>
        </div>
      </div>
    </b-form>

    <div class="row">
      <div class="col-12 p-5">
        <bar-chart
          v-if="items.length > 0"
          :chartData="chartData"
          :options="options"
        />
      </div>
    </div>

    <div class="mb-3" ref="table-container" v-show="items.length > 0">
      <small class="text-muted">Total rows: {{ rows }}</small>
      <b-table
        bordered
        small
        :responsive="true"
        :items="items"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :busy="isBusy"
        class="chat-histories-table"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #head(totalCountForBot)="data">
          <span
            v-b-tooltip.hover.top
            title="Start with Bot, including no question + Transferred to Agent, including no question"
            >{{ data.label }}</span
          >
        </template>
        <template v-slot:cell(date)="data"
          >{{ data.item.date | date }}
        </template>
        <template v-slot:custom-foot="data">
          <b-tr class="total-row">
            <b-td
              v-for="field in data.fields"
              :key="field.key"
              :class="{ 'text-right': field.key !== 'date' }"
            >
              {{ sum(data.items, field.key) }}
            </b-td>
          </b-tr>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import dayjs from 'dayjs';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import httpclient from '@/mixins/HttpHandler';
import BarChart from '@/components/Charts/BarChart.js';
import BotSelector from '@/components/UI/BotSelector';

export default {
  /*global _*/
  mixins: [httpclient],
  components: { DateRangePicker, BarChart, BotSelector },
  mounted() {
    const botId =
      Number(this.$route.params.id) || this.$store.state.selectedBotId;
    this.queryParams.botId = botId;

    let datepicker = document.getElementsByClassName('reportrange-text');
    datepicker[0].classList.add('form-control-sm');

    this.dateRange = {
      startDate: dayjs().add(-6, 'days').startOf('day').toDate(),
      endDate: dayjs().add(1, 'days').startOf('day').toDate(),
    };
  },
  data() {
    return {
      path: 'chatcountreport',
      queryParams: {
        fromDate: null,
        toDate: null,
        botId: null,
      },
      bots: [],
      dateRange: {},
      chartData: {},
      fields: [
        {
          key: 'date',
          label: 'Date',
          sortable: true,
        },
        {
          key: 'totalCount',
          label: 'Chats',
          sortable: true,
          tdClass: 'text-right',
        },
        {
          key: 'totalCountForBot',
          label: 'Chats (assigned to bot)',
          sortable: true,
          tdClass: 'text-right',
        },
        {
          key: 'startAgentCount',
          label: 'Start with Agent',
          sortable: true,
          tdClass: 'text-right',
        },
        {
          key: 'startBotCount',
          label: 'Start with Bot',
          sortable: true,
          tdClass: 'text-right',
        },
        {
          key: 'botToAgentCount',
          label: 'Start with Bot (transferred to Agent)',
          sortable: true,
          tdClass: 'text-right',
        },
        {
          key: 'noResponseAgent',
          label: 'Start with Agent (no question)',
          sortable: true,
          tdClass: 'text-right',
        },
        {
          key: 'noResponseBot',
          label: 'Start with Bot (no question)',
          sortable: true,
          tdClass: 'text-right',
        },
        {
          key: 'noResponseBotToAgent',
          label: 'Transferred to Agent (no question)',
          sortable: true,
          tdClass: 'text-right',
        },
      ],
      items: [],
      perPage: 120,
      currentPage: 1,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                beginAtZero: true,
                precision: 0,
              },
            },
          ],
          xAxes: [
            {
              stacked: true,
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
  },

  computed: {
    minDate() {
      return dayjs().add(-2, 'months').set('date', 1).startOf('day').toDate();
    },
    maxDate() {
      return dayjs().add(1, 'days').toDate();
    },
    rows() {
      return this.items.length;
    },
  },
  methods: {
    getData() {
      const vm = this;

      this.queryParams.fromDate = dayjs(this.dateRange.startDate).format(
        'YYYY-MM-DD HH:mm:ss'
      );
      this.queryParams.toDate = dayjs(this.dateRange.endDate).format(
        'YYYY-MM-DD HH:mm:ss'
      );

      vm.httpclient
        .post('api/chatcountreport', this.queryParams)
        .then(function (resp) {
          if (resp.data) {
            vm.items = resp.data;
            vm.currentPage = 1;

            let div = vm.$refs['table-container'];
            if (div) {
              div.scrollTop = 0;
            }
            let chartdata = {};
            chartdata.labels = _.map(vm.items, function (a) {
              return dayjs(a.date).format('YYYY-MM-DD');
            });
            let datasets = [];
            datasets.push({
              label: 'Start with Agent',
              backgroundColor: '#5899da',
              data: _.map(vm.items, 'startAgentCount'),
            });
            datasets.push({
              label: 'Start with Bot',
              backgroundColor: '#e8743b',
              data: _.map(vm.items, 'startBotCount'),
            });
            datasets.push({
              label: 'Start with Bot (transferred to Agent)',
              backgroundColor: '#19a979',
              data: _.map(vm.items, 'botToAgentCount'),
            });
            datasets.push({
              label: 'Start with Agent (no question)',
              backgroundColor: '#ed4a7b',
              data: _.map(vm.items, 'noResponseAgent'),
            });
            datasets.push({
              label: 'Start with Bot (no question)',
              backgroundColor: '#945ecf',
              data: _.map(vm.items, 'noResponseBot'),
            });
            datasets.push({
              label: 'Transferred to Agent (no question)',
              backgroundColor: '#bf399e',
              data: _.map(vm.items, 'noResponseBotToAgent'),
            });
            chartdata.datasets = datasets;
            vm.chartData = chartdata;
          } else {
            vm.items = [];
          }
        })
        .catch(function () {
          vm.items = [];
        });
    },
    defaultRanges() {
      const today = dayjs().startOf('day').toDate();
      const tomorrow = dayjs().add(1, 'days').startOf('day').toDate();
      // const yesterday = dayjs().add(-1, 'days').startOf('day').toDate();
      const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      const thisMonday = dayjs().startOf('isoWeek');
      const prevMonday = dayjs().add(-7, 'days').startOf('isoWeek');

      // const previousHour = dayjs().add(-1, 'hours').startOf('hour').toDate();
      // const thisHour = dayjs().startOf('hour').toDate();
      // const nextHour = dayjs().add(1, 'hours').startOf('hour').toDate();

      return {
        // 'Previous Hour': [previousHour, thisHour],
        // 'This Hour': [thisHour, nextHour],
        // Today: [today, tomorrow],
        // Yesterday: [yesterday, today],
        'Previous Week': [prevMonday, thisMonday],
        'This Week': [thisMonday, tomorrow],
        'This Month': [thisMonthStart, tomorrow],
      };
    },
    sum(items, key) {
      if (key === 'date') {
        return 'Total';
      } else {
        return _.sumBy(items, key);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.total-row {
  border-top: 2px solid #21252950;
  border-bottom: 2px solid #21252950;
}
</style>
